<template>
  <div class="app-container">
    <CrudTable
      entity="LocalProduct"
      :columns="columns"
      :generate-summary="generateSummary"
      :permission-code="'MyLocalProducts'"
      :default-filters="{ onlineYN: 1 }"
    />
  </div>
</template>

<script>
import CrudTable from '@/components/crud/CrudTable';
import { booleanColumnFilter, booleanColumnFormatter, priceFormatter, formatOption } from '@/utils/filters';

export default {
  components: {
    CrudTable
  },
  data() {
    return {
      columns: [
        { field: 'name' },
        {
          field: 'localProductCategoryName',
          label: 'localProduct.localProductCategory',
          filter: {
            type: 'select',
            options: this.localProductCategories,
            valueField: 'name',
            labelField: 'name'
          }
        },
        { field: 'price', formatter: priceFormatter },
        {
          field: 'vatRateOption',
          label: 'common.vatRateOption',
          minWidth: '100px',
          sortable: false,
          formatter: formatOption(this, 'vatRate'),
          filter: {
            type: 'select',
            options: this.$store.getters['option/loadedOptions']('vatRate'),
            valueField: 'code',
            labelField: 'description'
          }
        },
        { field: 'ean' },
        { field: 'deposit', label: 'product.deposit', formatter: priceFormatter },
        {
          field: 'onlineYN',
          label: 'common.onlineYN',
          filter: booleanColumnFilter(this),
          formatter: booleanColumnFormatter(this)
        }
      ]
    };
  },
  computed: {
    localProductCategories() {
      return this.$store.getters['localProductCategory/loadedItems'];
    }
  },
  watch: {
    localProductCategories(localProductCategories) {
      this.getColumn('localProductCategoryName').filter.options = localProductCategories;
    }
  },
  created() {
    this.$store.dispatch('localProductCategory/getItems');
  },
  methods: {
    getColumn(fieldName) {
      return this.columns.find(c => c.field === fieldName);
    },
    generateSummary({ name }) {
      return `${name}`;
    }
  }
};
</script>

<style scoped></style>
